import React from "react"
import SEO from "../../components/seo"
import Layout from "../../components/layout"
import {Link} from "gatsby";
import styled from 'styled-components';

const links = [
	{
		name: 'Setting and Infrastructure',
		path: '/ui-green-metric/setting-and-infrastructure/'
	},
	{
		name: 'Energy and Climate Change',
		path: '/ui-green-metric/energy-and-climate-change/'
	},
	{
		name: 'Waste',
		path: '/ui-green-metric/waste/'
	},
	{
		name: 'Water',
		path: '/ui-green-metric/water/'
	},
	{
		name: 'Transportation',
		path: '/ui-green-metric/transportation/'
	},
	{
		name: 'Education and Research',
		path: '/ui-green-metric/education-and-research/'
	},
	{
		name: 'Green Office',
		path: '/ui-green-metric/green-office/',
	}
];

const LinkListWrapper = styled.ul`
	display: flex;
	flex-direction: column;
`;

const StyledLink = styled(Link)`
	text-decoration: none;
	color: black;
	
	:hover  {
		color: grey;
	}
`;

const EnvironmentPage = () => {
  return (
    <Layout>
      <SEO title={"UI GreenMetric"} />
      <h1 className={"title"}>UI Green Metric</h1>
		<LinkListWrapper>
			{
				links.map(({path, name}) => (
					<li>
						<StyledLink to={path} key={name}>
							{name}
						</StyledLink>
					</li>
				))
			}
		</LinkListWrapper>
    </Layout>
  )
};

export default EnvironmentPage
